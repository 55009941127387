import axios from "axios";
import { get } from "lodash";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

const token = localStorage.getItem("token");

const apiConnection = axios.create({
  baseURL: API_URL,
  timeout: 10000,
  crossDomain: true,
  headers: {
    authorization: token ? `Bearer ${token}` : undefined,
  }
});

apiConnection.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = get(error, "response.status", null);
    if (!status) {
      return axios.request(error.config);
    }
    if (status === 401) {
      console.warn({ error });
      localStorage.removeItem("token");
    }
    return Promise.reject(error);
  }
);

export default {
  ping: () => apiConnection.get("/"),
  getEnums: () => apiConnection.get(`/enums`),
  getPricingList: () => apiConnection.get("/pricing"),
};
