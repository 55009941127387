import React from 'react';
import ReactDOM from 'react-dom';

import "core-js";
import 'es6-promise/auto';

import Pricing from './Pricing';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(<Pricing />, document.getElementById('root'));
serviceWorker.unregister();
