import React, { Component } from 'react';
import ApiService from './services/ApiService';

import './Pricing.css';

export default class Pricing extends Component {

  state = {
    loaded: false,
    prices: [],
  }


  componentWillMount() {
    ApiService.getPricingList()
      .then(res => res.data)
      .then(prices => this.setState({ prices, loaded: true }));
  }

  render() {
    const { loaded, prices } = this.state;
    return (
      <div className='row'>
        <div className='col-12'>
          <h3>Ceník</h3>
          {loaded && <div id='price'>
            {prices.map(price => (<div className='priceRow'>
              <div className='title'>{price.title}</div>
              <div className='price'>{price.price}</div>
            </div>))}
          </div>}
        </div>
        <div className='col-12 mt-5'>
          <p><small>Dohodnuté termíny lze zrušit 48 hodin předem, v ostatních případech účtuji celou částku.
          Objednávejte se, prosím, prostřednictvím e-mailu: <a href='mailto:LavickaJana@seznam.cz'>LavickaJana@seznam.cz</a></small></p>
        </div>
      </div>
    );
  }

}
